import { t } from '@zupr/i18n'
import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'

import { useDomain } from '../../../../context/domain'
import { useIsMobile } from '../../../../context/ux'

import headerImage from '../../../../../img/header/desktop/header.webp'
import headerImageClouds from '../../../../../img/header/mobile/clouds.webp'
import headerImagePark from '../../../../../img/header/mobile/park.webp'
import headerImageRetailer from '../../../../../img/header/mobile/retailer.webp'

import '../../../../../scss/react/pages/big-header.scss'
import './default.scss'

const DiscoverHeader = () => {
    const { entrepreneurStories, payoff, name, brand } = useDomain()
    const isMobile = useIsMobile()

    return (
        <div className="big-header big-header-default">
            <div className="image-holder">
                {!isMobile && (
                    <img
                        loading="eager"
                        src={headerImage.src}
                        alt={brand}
                        className="default-header-image"
                    />
                )}
                {isMobile && (
                    <React.Fragment>
                        <img
                            loading="eager"
                            src={headerImageClouds.src}
                            alt={brand}
                            className="clouds"
                        />
                        <img
                            loading="eager"
                            src={headerImagePark.src}
                            alt={brand}
                            className="park"
                        />
                        <img
                            loading="eager"
                            src={headerImageRetailer.src}
                            alt={brand}
                            className="retailer"
                        />
                    </React.Fragment>
                )}
            </div>
            <div className="big-header-content">
                <div className="titles">
                    <h1>{brand}</h1>
                    <p>{payoff}</p>
                </div>

                <div
                    className={classnames({
                        'desktop-links': !isMobile,
                        'mobile-links': isMobile,
                    })}
                >
                    <h4>
                        {t('Discover %{name}', {
                            name,
                        })}
                    </h4>
                    <ul>
                        <li>
                            <Link href="/producten">
                                <a>{t('Products')}</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/winkels">
                                <a>{t('Stores')}</a>
                            </Link>
                        </li>
                        {!!entrepreneurStories?.length && (
                            <li>
                                <Link href="/ondernemers">
                                    <a>{t('Entrepreneurs')}</a>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default DiscoverHeader
